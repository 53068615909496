.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 2vh;
  max-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.weather-plot-div {
  width: 100%;
  height: 100%;
}

.weather-plot {
  width: 100%;
  height: 100%;
}

.dateselector-box {
    margin-bottom: 0px;
    margin-top: 0px;
    height: 50px;
    text-align: right;
    vertical-align: middle;
    horiz-align: center;
    align-items: center;
}

.flexbox-container {
    display: flex;
    flex-direction: row;
    background-color: lightgray;
}

.date-selector-text-field {
    width: 250px;
    text-align: right;
}

.div-weather-element {
    height: calc(70vh);
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

html, body, #app, #app>div {
  height: 100%
}

#root {
    height: 100%;
}